import play from '../images/page-header/links/play.svg';
import catalog from '../images/page-header/links/catalog.svg';
import pricing from '../images/page-header/links/pricing.svg';
import learnMore from '../images/page-header/links/learn_more.svg';
import solutions from '../images/page-header/links/solutions.svg';
import quizzes from '../images/page-header/links/quizzes.svg';
import logoutIcon from '../images/page-header/icons/logout_icon.svg';
import profileIcon from '../images/page-header/icons/profile_icon.svg';
import archiveIcon from '../images/page-header/icons/archive_icon.svg';
import linksIcon from '../images/page-header/icons/links_icon.svg';
import helpCenter from '../images/page-header/links/help_center.svg';
import { MainMenuSchemeModel } from '../components/main-menu/types';
import { MANAGE_SITE_URL, GAME_SITE_URL, MAIN_SITE_URL } from 'gatsby-env-variables-fork';
import { Translate } from '../locales/locales-utils';

export const mainMenuUsScheme: MainMenuSchemeModel = {
  links: [
    {
      name: 'Play',  
      href: `${GAME_SITE_URL}`,
      imageSrc: play,
      isAuthorized: true,
    },
    {
      name: "I'm a Player",
      href: `${GAME_SITE_URL}`,
      imageSrc: play,
      isAuthorized: false,
    },
    {
      name: 'Pricing',
      href: '/pricing/',
      imageSrc: pricing,
      isCommon: true,
    },
    {
      name: 'Solutions',
      imageSrc: solutions,
      isMenuOpen: false,
      isCommon: true,
      subLinks: [
        {
          name: 'Team building',
          href: '/team-building/',
          imageSrc: '',
        },
        {
          name: 'Brand promotion',
          href: '/brand-promotion/',
          imageSrc: '',
        },
        {
          name: 'Lead generation',
          href: '/lead-generation/',
          imageSrc: '',
        },
        {
          name: 'Private event',
          href: '/private-event/',
          imageSrc: '',
        },
        {
          name: 'Sports/eSports',
          href: '/sport/',
          imageSrc: '',
        },
        {
          name: 'Training/Webinar',
          href: '/training/',
          imageSrc: '',
        },
        {
          name: 'Education/eLearning',
          href: '/education/',
          imageSrc: '',
        },
        {
          name: 'Fundraising',
          href: '/fundraising/',
          imageSrc: '',
        },
        {
          name: 'Branding',
          href: '/branding/',
          imageSrc: '',
        },
        {
          name: 'Advertising',
          href: '/ad/',
          imageSrc: '',
        },
        {
          name: 'Enterprise',
          href: '/enterprise/',
          imageSrc: '',
        },
        {
          name: 'Investors',
          href: '/investors/',
          imageSrc: '',
        },
        {
          name: 'Dance',
          href: '/dance/',
          imageSrc: '',
        }
      ],
    },
    {
      name: 'My quizzes',
      href: `${MANAGE_SITE_URL}/Lectures`,
      imageSrc: quizzes,
      isAuthorized: true,
    },
    {
      name: 'Marketplace',
      href: '/marketplace/',
      imageSrc: catalog,
      isCommon: true,
    },
    {
      name: 'Learn more',
      imageSrc: learnMore,
      isMenuOpen: false,
      isCommon: true,
      subLinks: [
        {
          name: 'Blog',
          href: 'https://www.blog.myquiz.org/',
          imageSrc: '',
        },
        {
          name: 'Help Center',
          href: 'https://help.myquiz.org/',
          imageSrc: '',
        },
        {
          name: 'Contact us',
          href: '/contacts/',
          imageSrc: '',
        },
      ],
    },
  ],
  profileMenuLinks: [
    {
      name: 'Settings',
      href: `${MANAGE_SITE_URL}/Account/UserProfile`,
      imageSrc: profileIcon,
    },
    {
      name: 'Archive',
      href: `${MANAGE_SITE_URL}/Archive`,
      imageSrc: archiveIcon,
    },
    {
      name: 'Links',
      href: `${MANAGE_SITE_URL}/Links`,
      imageSrc: linksIcon,
    },
    {
      name: 'Logout',
      href: `${MANAGE_SITE_URL}/Account/LogOff?RedirectUrl=${MAIN_SITE_URL}`,
      imageSrc: logoutIcon,
    },
  ],
};

export const mainMenuRuScheme = (): MainMenuSchemeModel => {
  return {
    links: [
      {
        name: Translate('MainMenu.Play'), 
        href: `${GAME_SITE_URL}`,
        imageSrc: play,
        isAuthorized: true,
      },
      {
        name: Translate('MainMenu.IamPlayer'),
        href: `${GAME_SITE_URL}`,
        imageSrc: play,
        isAuthorized: false,
      },
      {
        name: Translate('MainMenu.Pricing'),
        href: '/pricing/',
        imageSrc: pricing,
        isCommon: true,
      },
      {
        name: Translate('MainMenu.Solutions.Name'),
        imageSrc: solutions, 
        isMenuOpen: false,
        isCommon: true,
        subLinks: [
          {
            name: Translate('MainMenu.Solutions.SubLinks.TeamBuilding'),
            href: '/team-building/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.BrandPromotion'),
            href: '/brand-promotion/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.LeadGeneration'),
            href: '/lead-generation/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.PrivateEvent'),
            href: '/private-event/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.Sport'),
            href: '/sport/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.Training'),
            href: '/training/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.Education'),
            href: '/education/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.Events'),
            href: '/events/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.Branding'),
            href: '/branding/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.Promo'),
            href: '/promo/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.Investors'),
            href: '/investors/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.Solutions.SubLinks.Dance'),
            href: '/dance/',
            imageSrc: '',
          }
        ],
      },
      {
        name: Translate('MainMenu.Quizzes'),
        href: `${MANAGE_SITE_URL}/Lectures`,
        imageSrc: quizzes,
        isAuthorized: true,
      },
      {
        name: Translate('MainMenu.CatalogOfQuizzes.Name'), 
        imageSrc: catalog,
        isCommon: true, 
        subLinks: [ 
          {
            name: Translate('MainMenu.CatalogOfQuizzes.SubLinks.CatalogOfMyQuiz'),
            href: '/community/myquiz/',
            imageSrc: '',
          },
          {
            name: Translate('MainMenu.CatalogOfQuizzes.SubLinks.CatalogOfCommunity'),
            href: '/community/',
            imageSrc: '',
          },
        ],
      },
      {
        name: Translate('MainMenu.HelpCenter'),
        href: 'https://myquiz.notion.site/myQuiz-ru-d7c7e8975fb24308a1d9114df57d464b',
        imageSrc: helpCenter,
        isCommon: true,
        target: '_blank'
      },
    ],
    profileMenuLinks: [
      {
        name: Translate('MainMenu.Settings'),
        href: `${MANAGE_SITE_URL}/Account/UserProfile`,
        imageSrc: profileIcon,
      },
      {
        name: Translate('MainMenu.Archive'),
        href: `${MANAGE_SITE_URL}/Archive`,
        imageSrc: archiveIcon,
      },
      {
        name: Translate('MainMenu.Links'),
        href: `${MANAGE_SITE_URL}/Links`,
        imageSrc: linksIcon,
      },
      {
        name: Translate('MainMenu.Logout'),
        href: `${MANAGE_SITE_URL}/Account/LogOff?RedirectUrl=${MAIN_SITE_URL}`,
        imageSrc: logoutIcon,
      },
    ],
  }
};
